<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "app",
  async beforeCreate() {
    const discordId =
      this.$store.state.discord_user && this.$store.state.discord_user.id;

    if (discordId) {
      this.axios
        .get("/api/v1/globalinfo", {
          params: {
            user_id: discordId
          }
        })
        .then(res => {
          const isPremium = res && res.data && res.data.ispremium;
          this.$store.commit("updateGlobalInfo", { isPremium: isPremium });
        })
        .catch(error => {
          this.$store.commit("updateGlobalInfo", { isPremium: false });
          console.log(error);
        });
    } else {
      this.$store.commit("updateGlobalInfo", { isPremium: false });
    }

    fetch("https://geodata.botlabs.gg", {
        withCredentials: false
      })
      .then(res => res.json())
      .then(data => {
        this.$store.commit("updateUserGeo", data);
      })
      .catch(error => {
        this.$store.commit("updateUserGeo", {});
        console.log(error);
      });
  }
};
</script>

<style lang="scss">
// CoreUI Icons Set
//@import '~@coreui/icons/css/coreui-icons.min.css';
/* Import Font Awesome Icons Set */
// $fa-font-path: "~\@fortawesome/fontawesome-free/webfonts/";
// @import "~\@fortawesome/fontawesome-free/scss/font-awesome.scss";

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts/";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
/* Import Simple Line Icons Set */
$simple-line-font-path: "~simple-line-icons/fonts/";
@import "~simple-line-icons/scss/simple-line-icons.scss";
/* Import Flag Icons Set */
/* Import Bootstrap Vue Styles */
@import "~bootstrap-vue/dist/bootstrap-vue.css";
// Import Main styles for this application
@import "assets/scss/style";

.mini-toastr {
  top: 67px !important;
  position: fixed;
  right: 12px;
  z-index: 99999;
}
div.mini-toastr-notification__message {
  padding: 0;
}
div.mini-toastr__notification {
  opacity: 1 !important;
}
</style>

<style>
@import url("https://fonts.googleapis.com/css?family=Poppins:700,400|Roboto&display=swap");

.title {
  color: #fefefe;
  margin-top: 55px;
  font-family: poppins;
  font-weight: bold;
  font-size: 60px;
}

.subtitle {
  color: #fefefe;
  font-family: poppins;
  font-weight: bold;
  font-size: 30px;
}
.smalltitle {
  color: #fefefe;
  font-family: poppins;
  font-weight: bold;
  font-size: 22px;
}
.tinytitle {
  color: #fefefe;
  font-family: poppins;
  font-weight: bold;
  font-size: 17px;
}

.teenytinytitle {
  color: #fefefe;
  font-family: poppins;
  font-weight: bold;
  font-size: 14px;
}
</style>
