import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

export default new Vuex.Store({
  state: {
    discord_user: {},
    discord_guilds: [],
    stats: {},
    onboardingStep: 0,
    oauthState: {},
    user_geo: {},
    globalInfo: {}
  },
  mutations: {
    login(state, obj) {
      state.discord_user = obj;
    },
    logout(state) {
      while (state.discord_guilds.length > 0) {
        state.discord_guilds.pop();
      }
      state.discord_user = {};
      //state.discord_guilds = []
    },
    sync_guilds(state, lst) {
      state.discord_guilds = lst;
    },
    toggle_premium(state, id) {
      state.discord_guilds.forEach(g => {
        if (g.id == id) {
          g.vip = !g.vip;
        }
      });
      state.discord_guilds;
    },
    sync_stats(state, stats) {
      state.stats = stats;
    },
    onboardingStage(state, step) {
      state.onboardingStep = step;
    },
    syncOauth(state, payload) {
      state.oauthState = payload;
    },
    newChannel(state, payload) {
      state.oauthState.channels.push(payload);
    },
    updateUserGeo(state, payload) {
      state.user_geo = payload;
    },
    updateGlobalInfo(state, payload) {
      state.globalInfo = payload;
    }
  },
  plugins: [vuexLocal.plugin]
});
